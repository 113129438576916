import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, OnDestroy} from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import firebase from 'firebase';

import { OrderingService } from '../ordering.service';
import { KeywordsCustom } from './custom-validators'

const v_end_point:string = '/vent_orderer'
const pand_endpoint:string = '/pand_orderer'


@Component({
  selector: 'app-cv-select',
  templateUrl: './cv-select.component.html',
  styleUrls: ['./cv-select.component.css']
})

export class CvSelectComponent implements OnInit {

  keywords: string;
  admin: boolean;

  f_keywords: FormControl;

  constructor(
    private fb: FormBuilder,
    private Router: Router,
    private ord_ser: OrderingService
  ) { 
    this.keywords = '';
    this.admin = false;

    this.f_keywords = this.fb.control('', [
      Validators.required,
      KeywordsCustom.contentValidator({invalidContent: true }),
      KeywordsCustom.lenghtValidator({invalidLenght: true })
    ])

    this.f_keywords.valueChanges.subscribe( x => {
      console.log('keywords value is: ', x)
      this.keywords = x
    })
  }

  onSubmitVent() {
    console.log("petición de ordenación - partner")
    firebase.auth().currentUser!.getIdToken(/* forceRefresh */ true).then((idToken) =>  {
      this.ord_ser.push_ordering(this.keywords, idToken, v_end_point)
      this.Router.navigate(['zip-download'])
    })
  }

  onSubmitPand() {
    console.log("petición de ordenación - admin")
    firebase.auth().currentUser!.getIdToken(/* forceRefresh */ true).then((idToken) =>  {
      this.ord_ser.push_ordering(this.keywords, idToken, pand_endpoint)
      this.Router.navigate(['zip-download'])
    })
  }

  ngOnInit() {
    this.keywords = ''
    this.admin = firebase.auth().currentUser!.email?.includes("pandora-doc.com")!
  }
}