import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router'
import { IdentityService } from '../identity.service'
import { identifierModuleUrl, ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  f_email: FormControl;
  f_password: FormControl;

  email: string;
  password: string;
  error_login = false;


  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    private Router: Router,
    private id_ser: IdentityService,
  ) { 
     
    this.f_email = this.fb.control('', [
      Validators.email, Validators.required
    ])
    this.f_password = this.fb.control('', Validators.required)

    this.email = ''
    this.password = ''

    this.f_email.valueChanges.subscribe( x => {
      console.log('email value is: ', x)
      this.email = x
    })

    this.f_password.valueChanges.subscribe( x => {
      console.log('password changed')
      this.password = x
    })

  }

  ngOnInit(): void {
    this.email = ''
    this.password = ''
  }

  onLogin(): void {
    this.login(this.email, this.password)
  }

  login( email: string, password: string) {
    this.error_login = false;

    this.afAuth.signInWithEmailAndPassword(email, password)
        .catch(error => {
          console.log('unable to login: ', error);
          this.error_login = true;
        })
        .then(userCredential => {
          if(userCredential) {
            console.log('successful login: ', userCredential)
            this.error_login = false
            this.id_ser.set_identity(userCredential)
            this.Router.navigate(['cv-select'])
          }
        })
  }

}
