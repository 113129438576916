<div>
    <form>
        <mat-grid-list cols="1" rowHeight="25px" [gutterSize]="'4px'">
            <mat-grid-tile [rowspan]="2">
                <mat-form-field class="email" >
                    <mat-label>email</mat-label>
                    <input type="email" matInput [formControl]="f_email"
                        placeholder="ej: cuenta@corp.com" name="email">
                    <mat-hint>cuenta@corp.com</mat-hint>
                    <mat-error *ngIf="f_email.hasError('required')">
                        email es <strong>obligatorio</strong>
                    </mat-error>
                    <mat-error *ngIf="f_email.hasError('email')">
                        input <strong>no</strong> tiene formato de email
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]="6">
                <mat-form-field class="password">
                    <mat-label>clave</mat-label>
                    <input type="password" matInput [formControl]="f_password" 
                        name="password" >
                    <mat-hint>clave: mínimo 7 caracteres, mayúsculas, minúsculas, un caracter especial, una cifra</mat-hint>
                    <mat-error *ngIf="f_password.hasError('required')">
                        clave es <strong>obligatorio</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]="2">
                <button mat-button type="submit" (click)="onLogin()" [disabled]="f_email.errors !== null || f_password.errors !== null"> acceso a la cuenta </button>
                <mat-error *ngIf="error_login">
                    usuario / clave <strong>desconocida</strong>
                </mat-error>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
