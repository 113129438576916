<div>
    <form>
        <mat-grid-list cols="1" rowHeight="25px" [gutterSize]="'4px'">
            <mat-grid-tile [rowspan] = "2">
                <h2>download resultados de ordenación (zip)</h2>
            </mat-grid-tile>
            <mat-grid-tile [rowspan] = "1">
                <a routerLink="/cv-select">petición de cv's ordenados</a>
            </mat-grid-tile>
            <mat-grid-tile [rowspan] = "20">
                <table mat-table [dataSource]="dataSource">
                    <!--- file_name column -->
                    <ng-container matColumnDef="file_name">
                        <th mat-header-cell *matHeaderCellDef>nombre de fichero</th>
                        <td mat-cell *matCellDef="let element">{{element.file_name}}</td>
                    </ng-container>
                    <!--- create_time column -->
                    <ng-container matColumnDef="create_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>creado</th>
                        <td mat-cell *matCellDef="let element">{{element.create_time}}</td>
                    </ng-container>
                    <!--- select column -->

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                          <mat-checkbox color="primary"
                                        (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                          </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <mat-checkbox color="primary"
                                        (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)">
                          </mat-checkbox>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </mat-grid-tile>
            <mat-grid-tile [rowspan] = "2">
                <button mat-button type="submit" (click)="download()" [disabled]="selection.selected.length == 0">download zip</button>
            </mat-grid-tile>
            <mat-grid-tile [rowspan] = "2">
                <button mat-button type="submit" (click)="refresh_list()">refresca lista de ficheros</button>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>