// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDhX-StybOSH26F4T9-cCef63tfrKaO_bg",
    authDomain: "hesiod.firebaseapp.com",
    projectId: "hesiod",
    storageBucket: "hesiod.appspot.com",
    messagingSenderId: "186513956164",
    appId: "1:186513956164:web:c2115bce40449b0cd0cb11",
    measurementId: "G-DNG4QXJC2X"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
