import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  identityChange: BehaviorSubject<firebase.auth.UserCredential | null> = new BehaviorSubject<firebase.auth.UserCredential | null> (null);

  get_identity(): BehaviorSubject<firebase.auth.UserCredential | null> {
    return this.identityChange
  }

  set_identity(userIdentity: firebase.auth.UserCredential): void {
    console.log("email value is: ", userIdentity.user!.email)
    this.identityChange.next(userIdentity);
  }

}
