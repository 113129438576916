
<div>
    <form>
        <mat-grid-list cols="2" rowHeight="25px">

            <mat-grid-tile [rowspan] = "2" [colspan]="2">
                <h2>petición de cv's ordenados</h2>
            </mat-grid-tile>

            <mat-grid-tile [rowspan]="3" [colspan]="2">
                <div class="keywords">
                    <mat-form-field>
                        <mat-label>keywords</mat-label>
                        <input type="text" matInput [formControl]="f_keywords"
                        placeholder="lista de las palabras claves separadas por espacios o coma" name="keywords">
                        <mat-hint>comprador, tecnología, ... mínimo 5 palabras 4 caracteres</mat-hint>
                        <mat-error *ngIf="f_keywords.hasError('required')">
                            list de palabras claves es <strong>obligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="f_keywords.hasError('invalidLenght')">
                            mínimo de <strong>10</strong> palabras clave
                        </mat-error>
                        <mat-error *ngIf="f_keywords.hasError('invalidContent')">
                            palabras clave mínimo de <strong>4</strong> caracteres 
                        </mat-error>
                    </mat-form-field>
                </div>

            </mat-grid-tile>
            <mat-grid-tile [rowspan]="2" [colspan]="1">
                        <button mat-button type="submit" (click)="onSubmitVent()" [disabled]="(f_keywords.errors !== null)"> recuperar cv's - partner</button>
            </mat-grid-tile>
            <mat-grid-tile [rowspan]="2" [colspan]="1">
                        <button mat-button type="submit" (click)="onSubmitPand()" [disabled]="(f_keywords.errors !== null)&&(admin)"> recuperar cv's - todos</button>
            </mat-grid-tile>

            <mat-grid-tile [rowspan] = "1" [colspan]="2">
                <a routerLink="/zip-download">Download resultados de ordenación (zip)</a>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
