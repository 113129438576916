import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs'

import firebase from 'firebase';
const url = 'https://admin-r3pvuo3jkq-uc.a.run.app'
// const url = 'http://0.0.0.0:8080'
@Injectable({
  providedIn: 'root'
})
export class OrderingService {

  constructor(
    private http:HttpClient
  ) { }

  push_ordering(keywords: string, idToken: string, end_point:string): void {
    // this only get venturessoft Cv's
    // We should let pandora adming to call this endpoint, but we need another for getting cv from any corpus.
    // this option should not be visible for the user
    const order_url = url + end_point;
    var params = new HttpParams();
    params = params.append('keywords', keywords);
    params = params.append('auth', idToken)

    this.http.get(order_url, { params: params }).subscribe(
        {
        error: 
          (error) => {
            console.log("error from server: ", error)
          },
        next:
          (response) => {
            console.log("response from server: ", response)
          }
        })
  }

  get_files(idToken: string): Observable<string> {
    const list_url = url + '/list_files';
    var params = new HttpParams();
    params = params.append('auth', idToken)
    return this.http.get(list_url, {params: params, responseType: 'text'}) // TODO: need to get date of creation to allow ordering of files. 
  }
}