import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component'
import { CvSelectComponent } from './cv-select/cv-select.component'
import { ZipDownloadComponent } from './zip-download/zip-download.component'

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent},
  { path: 'cv-select', component: CvSelectComponent},
  { path: 'zip-download', component: ZipDownloadComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
