
import { Component, OnDestroy } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Injectable } from '@angular/core';

import firebase from 'firebase';
import { Subscription, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections'
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';

import { saveAs } from 'file-saver';


import { OrderingService } from '../ordering.service'

class file_row {
  file_name: string = '';
  create_time: string = '';
}

function list_to_table(list_of_files: Array<file_row>): Array<file_row> {
  var table_e =  new Array

  console.log("list of files: ", list_of_files)

  for (let file in list_of_files) {
    var f_element = new file_row;
    console.log("file is: ", list_of_files[file])
    f_element.file_name = list_of_files[file].file_name;

    table_e.push(f_element)
    console.log('element is: ', f_element)
  }

  return table_e
}

@Injectable({providedIn: 'root'})
export class DownloadService {

  constructor(private http: HttpClient) {}

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}


@Component({
  selector: 'app-zip-download',
  templateUrl: './zip-download.component.html',
  styleUrls: ['./zip-download.component.css']
})
export class ZipDownloadComponent implements OnDestroy, AfterViewInit {
  subscription_list: Subscription | null = null;

  dataSource: MatTableDataSource<file_row>;

  displayColumns: Array<string> = ['select', 'file_name', 'create_time']
  selection: SelectionModel<file_row>

  @ViewChild(MatSort, { static: false }) set sort(val: MatSort) {
    if (val) {
      this.dataSource.sort = val;
    }
  }



  constructor(
    private or_ser: OrderingService,
    private storage: AngularFireStorage,
    private downloads: DownloadService
  ){

    const initialSelection: Array<file_row> = [];
    const allowMultiSelect = false;
    this.dataSource = new MatTableDataSource(initialSelection)

    this.selection = new SelectionModel<file_row>(allowMultiSelect, initialSelection);

    firebase.auth().currentUser!.getIdToken(/* forceRefresh */ true).then((idToken) =>  {
    this.subscription_list = this.or_ser.get_files(idToken).subscribe({
      next: (result) => {
        this.dataSource = new MatTableDataSource(JSON.parse(result).results)
        this.dataSource.sort = this.sort; 
        console.log('list loaded: ', this.dataSource)
      }
    })
  })
    
  }

  refresh_list(): void {
    firebase.auth().currentUser!.getIdToken(/* forceRefresh */ true).then((idToken) =>  {
      this.subscription_list = this.or_ser.get_files(idToken).subscribe({
        next: (result) => {
          this.dataSource = new MatTableDataSource(JSON.parse(result).results)
          this.dataSource.sort = this.sort; 
          console.log('list loaded: ', this.dataSource)
        }
      })
    })
  }

  ngOnDestroy(): void {
    this.subscription_list!.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort; 
  }

  download(): void {
    const file_name = this.selection.selected[0].file_name;
    firebase.auth().onAuthStateChanged((user) => {
      // User logged in already or has just logged in.
      const ref = this.storage.ref(user!.uid + '/' + file_name);
      ref.getDownloadURL().subscribe({
        next:((url) => {
          console.log(url)
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            var blob = xhr.response;
            saveAs(blob, file_name)
          };
          xhr.open('GET', url);
          xhr.send();
        })
    });
  })
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

/** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  } 

}
